import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
import Commit from "./Commit";
import RepoStore from "../../stores/RepoStore";

/**
 * Компонент для отображения списка записей о коммитах
 *
 * @param {Object} params набор параметров
 * @param {RepoStore} params.store Хранилище для работы с репозиторием
 * @param {Boolean} props.disabled флаг, указывающий, должна ли быть панель отображена или скрыта.
 * Скрытность панели делаем для того, чтобы не сбрасывать рендер и сохранить состояние открытых владок
 *
 * @type {CommitsView}
 * @returns {Component}
 */
const CommitsView = observer(({ path, store, disabled, goToNodeTree }) => {
  const { pending, commitPending, commitList, hasNextPage } = store;
  const target = useRef();
  const root = useRef();

  /**
   * Загружаем список коммитов для текущего репозитория
   */
  useEffect(() => {
    if (store.rootRepoNode) {
      store.clearCommits();
      store.loadCommits(!!path ? path : undefined);
    }
  }, [store.rootRepoNode, path]);

  // Набор компонент-записей с коммитами
  const commits = useMemo(() => {
    const commits = [];
    commitList.forEach((commitInfo) => {
      const { commit, author, commitMessage, dateString, id } = commitInfo;
      commits.push(
        <Commit
          path={path}
          goToNodeTree={goToNodeTree}
          commit={commit}
          author={author}
          id={id}
          key={id}
          commitMessage={commitMessage}
          dateString={dateString}
          store={store}
        />
      );
    });
    return commits;
  }, [commitList && commitList.length, path]);

  // Событие, когда пользователь пролистал список в конец
  // Делаем подгрузку новых записей
  const onInView = useCallback(
    (entries) => {
      if (!commitPending) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            store.getCommitsNextPage();
          }
        });
      }
    },
    [commitPending]
  );

  // Отображение прелоадера для отобрадения при подзагрузке следующих записей с коммитами
  const updateTrigger = useMemo(() => {
    return (
      <div id="observer" ref={target}>
        <Components.Preloader size={3} style={{ margin: "0.25rem" }} color="#01abfb" />
      </div>
    );
  }, []);

  // Определям момент, когда пользователь пролистал видимые записи до конца вниз
  // Если прослистал, то вызываем событие - onInView
  useEffect(() => {
    if (root && root.current) {
      const options = {
        root:       root.current,
        rootMargin: "0px",
        threshold:  0.25
      };
      const observer = new IntersectionObserver(onInView, options);
      if (target && target.current) {
        observer.observe(target.current);
      }
    }
  }, [root && root.current, target && target.current, commitPending]);

  return (
    <div
      className="commits-view"
      id={"commits-list"}
      ref={root}
      style={{
        display: disabled ? "none" : "flex"
      }}
    >
      {pending && (
        <div className="preloader-wrapper">
          <Components.Preloader size={3} />
        </div>
      )}
      {!pending && (
        <div className="commits">
          {commits}
          {hasNextPage && updateTrigger}
        </div>
      )}
    </div>
  );
});

CommitsView.propTypes = {
  store:    PropTypes.instanceOf(RepoStore),
  disabled: PropTypes.bool
};

export default CommitsView;

import { action, computed, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class Caption extends BaseBoxClass {
  @observable
  isNew = false;
  @observable
  textId = null;
  @observable
  prefix = null;
  @observable
  number = null;
  @observable
  creationPromice = null;

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.text) {
      this.textId = data.text;
    } else {
      const chunk = this.createChunk(true);
      chunk &&  this.addChunk(chunk);
    }
    this.prefix = data.prefix || null;
    this.number = data.number || null;
    if (data.isNew) {
      this.creationPromice = this.persistCreate();
      this.initData();
    }
  }

  @action
  lock() {
    this.parent && this.parent.lock();
  }

  @action
  unlock() {
    this.parent && this.parent.unlock();
  }

  @action
  async setEditing(offset, noFocus = false) {
    if (this.canSetEdit) {
      await(this.text && this.text.setEditing(offset, noFocus));
    }
  }

  @action
  update(data) {
    super.update(data);
    this.prefix = data.prefix || null;
    this.number = data.number || null;
  }

  @action
  async createOnlyChunk() {
    await this.creationPromice;
    const chunk = this.createChunk();
    this.addChunk(chunk);
    chunk.setEditing();
  }

  @action
  createChunk(notNew = false) {
    if (!this.store) {
      return null;
    }
    const newId = this.store.getUid();
    const chunk = this.store.createItem(
      { uid: newId, class: "text.chunk.Varchar", value: "", isNew: !notNew },
      this
    );
    return chunk;
  }

  @action
  addChunk(chunk) {
    this.textId = chunk.uid;
    chunk.setParent(this);
    return chunk;
  }

  // Метод вставки массива строк в индент
  @action 
  async splitAtItemWithArrayOfString(chunkUid, selectionInfo, stringArray) {
    const { startOffset, endOffset } = selectionInfo;
     
    const splitedItem = this.getItemById(chunkUid);
    splitedItem.spliceWithString(startOffset, endOffset - startOffset, stringArray.join(""));
  }
  // Метод для вставки индентов из массива
  @action
  async insertElementsFromArray(linesOfText, isFirstChar, isLastChar, chunkUid, selectionInfo) {
    const chunk = this.getItemById(chunkUid);
    if (!this.store) {
      return null;
    }
    if (linesOfText.length === 0) {
      return;
    }
    if (linesOfText.length === 1) {
      if (!chunk.value) {
        chunk.setValue(linesOfText[0], true);
      } else {
        this.splitAtItemWithArrayOfString(chunkUid, selectionInfo, linesOfText);
        return;
      }
    } else {
      if (!chunk.value) {
        chunk.setValue(linesOfText.join(" "), true);
      } else {
        this.splitAtItemWithArrayOfString(chunkUid, selectionInfo, linesOfText);
        return;
      }
    }
  }
    
  @computed
  get isCaption() {
    return true;
  }

  @computed
  get canBeFocused() {
    return false;
  }

  @computed
  get category() {
    return "heading";
  }

  @computed
  get slug() {
    return "indents";
  }

  @computed
  get text() {
    return this.getItemById(this.textId);
  }

  @computed
  get title() {
    if (this.prefix || this.number) {
      return `${this.prefix} ${this.number}: ${this.text && this.text.title}`;
    }
    return this.text && this.text.title;
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get output() {
    return {
      class:  this.className,
      uid:    this.uid,
      text:   this.textId,
      prefix: this.prefix,
      number: this.number
    };
  }
}

export default Caption;

import { action, computed, observable } from "mobx";
import Chunk from "./Chunk";

class InlineFormula extends Chunk {
  @observable
  captionId = null;

  @action
  init(data, parent) {
    super.init(data, parent);
    this.className = data.class;
    this.isNew = !!data.isNew;
    this.setValue(data.value || "", true);
  }

  @action
  setEditing(offset) {
    if (this.canSetEdit) {
      this.store.setEditingChunkId(this.uid, offset);
      this.store.setFocusUid(this.availableFocusUid);
      this.parent && this.parent.lock();
    }
  }

  @computed
  get title() {
    return "𝑓(𝑥)";
  }

  @computed
  get plainText() {
    return "𝑓(𝑥)";
  }

  @computed
  get flatItemsArray() {
    return [];
  }

  @computed
  get isFormulaChunk() {
    return true;
  }
}

export default InlineFormula;

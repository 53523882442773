import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Тектовое поле для набора текста
 * 
 * @param {Object} props набор параметров
 * @param {String} params.name имя поля  
 * @param {String} params.text  текстовое значение поля
 * @param {String} params.className  пользовательский className
 * @param {Boolean} params.readOnly поле для чтения - true или в режиме редактирования - false
 * @param {Function} params.onChange callback ф-я при изменнеия тектового содержимого поля
 */
const ContentEditable = (props) => {
  const { className, text, name, readOnly, hint, isValid } = props;
  const [initialValue, setText] = useState(text);

  // Схему с initialValue пришлось применить, чтобы при изменении значения в начало строки не "убегал" курсор
  useEffect(() => {
    if (!readOnly) {
      // обновляем  значение для contentEditable
      setText(text);
    }
  }, [readOnly]);

  const onChange = useCallback((e) => {
    const value = e.target.textContent;
    if (props.onChange) {
      props.onChange({
        target: {
          name,
          value
        }
      });
    }
  }, [name, text, props.onChange]);

  if (readOnly) {
    return (
      <div
        className={className}
      >
        {text}
      </div>
    );
  }
  return (
    <div className={classNames("content-editable-wrapper ais-field-container", {
      error: !isValid
    })}
    >
      <div
        className={className}
        onInput={onChange}
        onBlur={onChange}
        contentEditable="plaintext-only"
        // contentEditable={!readOnly}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: initialValue }}
      />
      {hint && <div className="ais-field-hint">{hint}</div>}
    </div>
  );
};

ContentEditable.propTypes = {
  className: PropTypes.string,
  text:      PropTypes.string,
  name:      PropTypes.string,
  hint:      PropTypes.string,
  isValid:   PropTypes.bool,
  readOnly:  PropTypes.bool,
  onChange:  PropTypes.func
};

export default React.memo(ContentEditable, (props, nextProps) => {
  if (props.text === nextProps.text && props.readOnly === nextProps.readOnly) {
    // don't re-render/update
    return true;
  }
  return false;
});

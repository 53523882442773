import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
// import { REPO_STATE_COMMIT } from "../../constants/repoStateTypes";
import RepoStore from "../../stores/RepoStore";


/**
 * Компонент для отображения записи о коммите
 *
 * @param {Object} params набор параметров
 * @param {String} params.id id записи с коммитом
 * @param {String} params.commit номер коммита
 * @param {String} params.author автор коммита
 * @param {String} params.commitMessage пользователькое сообщение о коммите
 * @param {String} params.dateString датаи время сделанного коммита в строковом представлении
 * @param {RepoStore} params.store Хранилище для работы с репозиторием
 *
 * @type {Commit}
 * @returns {Component}
 */
const Commit = observer((props) => {
  const { commit, author, commitMessage, dateString, id, store, path, goToNodeTree } = props;
  const currentCommitId = store.currentCommit?.id;
  const lastCommitId = store.lastCommit?.id;
  const onClick = useCallback(async() => {
    await store.goToCommit(path, id);
    goToNodeTree && goToNodeTree(path);
  }, [id, path, goToNodeTree]);

  const isCurrent = useMemo(() => {
    if (!!currentCommitId) {
      return id === currentCommitId; 
    }

    return id === lastCommitId;
  }, [id, currentCommitId, currentCommitId]);

  return (
    <div
      key={id}
      className={`commit-card ${isCurrent ? "current" : ""}`}
    >
      <div className="left">
        <div className="date-time">
          <Components.Icon className="expander big" name={"data-datetime-M"} />
          <span>{dateString}</span>
        </div>
        <div className="space" />
        <div className="commit">
          <Components.Icon className="expander big" name={"softdev-M"} />
          <span>{commit}</span>
        </div>
        <div className="user">
          <Components.Icon className="expander big" name={"user-M"} />
          <span>{author}</span>
        </div>
      </div>
      <div className="commit-message">
        <Components.Icon className="expander big" name={"comment-M"} />
        <span>{commitMessage}</span>
      </div>
      <div className="right">
        <Components.Button
          icon={"relink-M"}
          className={"go-to-commit-button"}
          color="action"
          text="Перейти"
          onPress={onClick}
          isDisabled={isCurrent}
        />
      </div>
    </div>
  );
});

Commit.propTypes = {
  id:            PropTypes.string,
  commit:        PropTypes.string, 
  author:        PropTypes.string, 
  commitMessage: PropTypes.string, 
  dateString:    PropTypes.string,  
  store:         PropTypes.instanceOf(RepoStore)
};

export default Commit;

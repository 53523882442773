import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import moment from "moment";
import { Dnd, ContextMenu } from "@ais3p/ui-framework";
import AisIcon from "~/core/components/AisIcon";
import { humanFileSize } from "~/core/utils";
import RepoStore from "../../stores/RepoStore";
import { MODE_VIEW_REPO } from "../../constants/modes";
import RepoNodeModel from "../../models/RepoNode";
import { DND_SOURCECODE_TYPE } from "~/core/constants/DnD";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import { CLS_REPO_FILE } from "~/core/constants/Classes";
import { CTX_MENU_AIS_OBJECT_LINK } from "~/core/constants/ContextMenu";

/**
 * Компонент для отображения ноды в дереве репозитория
 * 
 * @param {Object} params набор параметров
 * @param {RepoNode} params.node нода репозитория
 * @param {String} params.modeView режим просмотра деревера репозитория: 
 *                          MODE_VIEW_REPO | MODE_VIEW_SOOURCE | MODE_VIEW_COMMITS
 * @param {RepoStore} params.store хранилизе для работы с репозиторием
 * @param {Function} params.onClick callaback ф-я на клик по иконке ноды
 * @param {String} params.dateFormat формат дыты и времени коммита
 * 
 * @type {RepoNode}
 * @returns {Component}
 */
const RepoNode = observer(({ node, modeView, store, repoName, onClick, dateFormat = "DD.MM.YYYY HH:mm" }) => {
  const onIconClick = useCallback(() => {
    store.setSelectedNode(node);
    onClick && onClick(node);
  }, [node, onClick]);

  return (
    <div
      className={classNames("repo-node", {
        selected: node === store.selectedNode
      })}
    >
      <Dnd.Source          
        uid={node.uid}
        type={DND_SOURCECODE_TYPE}
        item={{
          tool:    DOMAIN_REPO,
          uid:     node.uid,
          title:   node.title,
          class:   node.class,
          version: 0
        }}
      >
        <ContextMenu.Trigger
          menuId={CTX_MENU_AIS_OBJECT_LINK}
          context={{ object: { ...node, repoName, title: node.title } }}
        >
          <div
            className={classNames("repo-node-name", {
              "repo-mode": modeView === MODE_VIEW_REPO
            })}
          >
            <AisIcon
              item={node}
              className="node-icon"
              onClick={onIconClick}
            />
            <span>{node.name}</span>
          </div>
        </ContextMenu.Trigger>
      </Dnd.Source>  
      {modeView === MODE_VIEW_REPO &&
        <div className="repo-node-info">
          <div className="repo-node-info-cell commit">
            {node.commitId}
          </div>
          <div className="repo-node-info-cell author">
            {node.author}
          </div>
          <div className="repo-node-info-cell commit-message">
            <p>{node.commitMessage}</p>
          </div>
          <div className="repo-node-info-cell commit-date">
            {moment(node.date).format(dateFormat)}
          </div>
          <div className="repo-node-info-cell size">
            {node.className === CLS_REPO_FILE && humanFileSize(node.size)}
          </div>
        </div>
      }
    </div>
  );
});

RepoNode.propTypes = {
  node:       PropTypes.instanceOf(RepoNodeModel).isRequired,
  modeView:   PropTypes.string,
  store:      PropTypes.instanceOf(RepoStore).isRequired,
  dateFormat: PropTypes.string,
  onClick:    PropTypes.func
};


export default RepoNode;

import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import AisIcon from "../../../core/components/AisIcon";
import PlusMenu from "./PlusMenu";
import { Components, ContextMenu, Dnd, Modal } from "@ais3p/ui-framework";
import FlatItem from "./FlatItem";

import { 
  DND_EDITORITEM_TYPE
} from "~/core/constants/DnD";
import { getClassTitle } from "../../../core/constants/Classes";

const TableCell = observer(({ data, renderItem, setContextMenu }) => {
  const {
    flatItemsArray,
    uid,
    idsArray,
    isHeaderCell,
    isExpanded,
    isHovered,
    isHoverValid,
    cs,
    rs,
    tableUid,
    scrollItemId,
    diffClass,
    canSetEdit,
    store: dataStore,
    additionalClasses,
    plusMenuItems
  } = data;

  const item = data;

  const [hoverItem, setHoverItem] = useState(null);
  const [canDropResult, setCanDropResult] = useState(false);

  const itemsRender = useMemo(() => {
    return flatItemsArray.map((itemData) => {
      return (
        <FlatItem
          key={itemData.uid} 
          item={itemData} 
          renderItem={renderItem}
          dataStore={itemData.store}
        />
      );
    });
  }, [idsArray, flatItemsArray]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const [dragItem, setDragItem] = useState(null);
  const [isDndPending, setIsDndPending] = useState(false);
  const [isDndDialogVisible, setIsDndDialogVisible] = useState(false);
  const [dndTargets, setDndTargets] = useState(null);
  const [targetIndex, setTargetIndex] = useState(null);

  const onConfirmDnd = useCallback(async() => {
    setIsDndPending(true);
    let target = dndTargets[targetIndex];
    if (!(target && target.ancorId)) {
      target = item;
    }
    await dataStore.move(dragItem, target);
    onCancelDnd();
  }, [dataStore, dragItem, dndTargets, targetIndex, item]);

  const onCancelDnd = useCallback(() => {
    setIsDndDialogVisible(false);
    setDragItem(null);
    setIsDndPending(false);
    setDndTargets(null);
    setTargetIndex(null);
  }, []);
  
  const dndDialogButtons = useMemo(() => {
    return [
      ( 
        <Components.Button
          key="add"
          text="Подтвердить"
          icon="ok-M"
          onPress={onConfirmDnd}
          isDisabled={(targetIndex === null || targetIndex === undefined) && (dndTargets && dndTargets.length > 1)}
          isLoading={isDndPending}
          color="positive"
        />
      ), (
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelDnd}
          isLoading={isDndPending}
          color="negative"
        />
      )
    ];
  }, [
    onConfirmDnd,
    targetIndex,
    isDndPending,
    onCancelDnd
  ]);

  const dndDialogContent = useMemo(() => {
    if (!dragItem) {
      return null;
    }

    if (dndTargets && dndTargets.length > 1) {
      return (
        <div className="dialog-content">
          <div className="dnd-text">
            Выберите уровень вложенности для {
              <AisIcon
                className={"dnd-content-confirm-icon"} 
                item={dragItem}
              />
            }{dragItem.title}
          </div>
          <div className="levels">
            {dndTargets.map((target, i) => {
              return (
                <Components.Button
                  key={`${target.title}`}
                  text={`${target.title}${dragItem.captionTitle}`}
                  icon={dragItem.iconString}
                  onPress={() => { // eslint-disable-line
                    setTargetIndex(i);
                  }}
                  color={i === targetIndex ? "action" : undefined}
                  isDisabled={isDndPending}
                />
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="dialog-content">
        Подтвердите перемещение для {
          <AisIcon
            className={"dnd-content-confirm-icon"} 
            item={dragItem}
          />
        } {dragItem.title}
      </div>
    );
  }, [dndTargets, dragItem, isDndPending, targetIndex]);

  const onDrop = useCallback((hoverItem, monitor) => {
    setHoverItem(null);
    if (monitor.isOver({ shallow: true })) {
      const dragItem = dataStore.getItemById(hoverItem.uid);
      setDragItem(dragItem);
      const targets = [];
      item.plusMenuItems.forEach((plusMenuItem) => {
        if (plusMenuItem.class === dragItem.className) {
          targets.push(plusMenuItem);
        }
      });
      setDndTargets(targets);
      setTargetIndex(0);
      setIsDndDialogVisible(!!targets.length);
    }
  }, [item, dataStore]);

  const canDrop = useCallback((dndItem) => {
    if (item.uid === dndItem.uid || item.pathSet.has(dndItem.uid)) { // no drop to SELF or SELF CHILDREN
      return false;
    }
    if (!(item.plusMenuItems && item.plusMenuItems.length)) { // no drop if no create after is available
      return false;
    }
    let canDrop = false;
    item.plusMenuItems.forEach((plusMenuItem) => {
      if (plusMenuItem.class === dndItem.class) {
        const realDnDItem = dataStore.getItemById(dndItem.uid);
        if (realDnDItem && !realDnDItem.hasKinds) {
          canDrop = true;
        } else {
          if (
            plusMenuItem.availableKindsArray
            && plusMenuItem.availableKindsArray.length 
            && realDnDItem && realDnDItem.kindsRepresentation
          ) {
            plusMenuItem.availableKindsArray.forEach((availableKind) => {
              realDnDItem.kindsRepresentation.kindUids.forEach((kindUid) => {
                if (kindUid === availableKind.kind) {
                  canDrop = true;
                }
              });
            });
          }
        }
      }
    });
    return canDrop;
  }, [item]);

  const onHover = useCallback(
    (props, monitor) => {
      const item = monitor.getItem();
      data.setHoveredById(item.uid);

      if (monitor.isOver({ shallow: true })) {
        setCanDropResult(monitor.canDrop());
        setHoverItem(item);
      } else {
        setCanDropResult(monitor.canDrop());
        setHoverItem(null);
      }
    },
    [data]
  );

  const endHover = useCallback(() => {
    setHoverItem(null);
  }, [setHoverItem, hoverItem]);

  const hoverItemRender = useMemo(() => {
    if (!hoverItem) {
      return null;
    }
    const item = data.store.getItemById(hoverItem.uid);
    return (
      <FlatItem
        renderItem={renderItem} item={item} dataStore={data.store}
        isPreview={true}
        canDropSelf={canDropResult}
      />
    );
  }, [hoverItem, renderItem, data.store, canDropResult]);

  const onMouseLeave = useCallback(() => {
    data.setExpanded(false);
  }, [data]);

  const contextMenuCollect = useCallback(
    () => {
      if (isHovered && isHoverValid) {
        setContextMenu([
          {
            icon:   "table-merge-M",
            title:  "Объеденить ячейки",
            action: "mergeCells"
          }
        ]);
      } else {
        const menuItems = [
          {
            icon:   "table-col-left-M",
            title:  "Вставить колонку слева",
            action: "insertColumnLeft"
          },
          {
            icon:   "table-col-right-M",
            title:  "Вставить колонку справа",
            action: "insertColumnRight"
          },
          {
            icon:   "table-row-top-M",
            title:  "Вставить строку сверху",
            action: "insertRowTop" 
          },
          {
            icon:   "table-row-bottom-M",
            title:  "Вставить строку снизу",
            action: "insertRowBottom" 
          }
        ];
  
        if (rs > 1) {
          menuItems.push({
            icon:   "table-divide-vert-M",
            title:  "Разбить ячейку по горизонтали",
            action: "splitCellVert"
          });
        } else {
          menuItems.push({
            icon:   "table-row-minus-M",
            title:  "Удалить строку",
            action: "deleteRow"
          });
        }
        if (cs > 1) {
          menuItems.push({
            icon:   "table-divide-hor-M",
            title:  "Разбить ячейку по вертикали",
            action: "splitCellHor"
          });
        } else {
          menuItems.push({
            icon:   "table-col-minus-M",
            title:  "Удалить колонку",
            action: "deleteColumn"
          });
        }
  
        setContextMenu(menuItems);
      }
    },
    [isHovered, cs, rs, setContextMenu, isHoverValid]
  );

  const canDrag = useCallback(() => {
    return !data.isVersion;
  }, [data.isVersion]);

  const endDrag = useCallback(
    (props, monitor) => {
      const did = monitor.didDrop();
      if (did || !isHoverValid) {
        data.setHoveredById();
      }
    },
    [data, isHoverValid]
  );

  const element = useRef();

  const hasItems = useMemo(() => {
    let size = 0;
    plusMenuItems && plusMenuItems.forEach((item) => {
      if (item && item.availableKindsArray && item.availableKindsArray.length) {
        size += item.availableKindsArray.length;
      }
    });
    return !!size;
  }, [plusMenuItems]);

  const cellContextMenuCollect = useCallback(
    () => {
      const menuItems = [];
      if (data) {
        if (hasItems) {
          const items = [];
          data.plusMenuItems.forEach((item) => {
            if (item.availableKindsArray.length) {
              const level = Math.ceil(item.level / 2);
              const children = [];
              item.availableKindsArray.forEach((availableKind) => {
                children.push({
                  icon:   availableKind.icon || item.icon,
                  title:  availableKind.title || getClassTitle(item.class),
                  action: "addItem",
                  data:   { ...item, kind: availableKind }
                });
              });
              if (children.length === 1) {
                items.push({ 
                  ...children[0], 
                  title: `${item.title || ""} ${children[0].title}${item.level >= 0 ? ` уровень ${level}` : ""}` 
                });
              } else if (children.length > 1) {
                items.push({
                  icon:  item.icon,
                  title: `${item.title}${item.level >= 0 ? ` уровень ${level}` : ""}`,
                  children
                });
              }
            }
          });
          menuItems.unshift({
            icon:     "newelement-before-M",
            title:    "Добавить",
            children: items
          });
        }
      }
      setContextMenu(menuItems);
    }, [setContextMenu, data, hasItems]);

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  const accept = useMemo(() => {
    if (itemsRender.length > 0) {
      return [`table-cell-handle-${tableUid}`];
    }
    return [`table-cell-handle-${tableUid}`, DND_EDITORITEM_TYPE];
  }, [itemsRender.length]);

  return (
    <div
      ref={element}
      className={`table-cell element ${additionalClasses} ${diffClass} ${
        isHeaderCell ? "header-cell" : ""
      } ${(isHovered && !(hoverItem && hoverItem.className !== "text.container.TableCell")) ? "hovered" : ""} ${isHoverValid ? "valid" : "invalid"}`}
      style={{
        gridColumnEnd: cs ? `span ${cs}` : undefined,
        gridRowEnd:    rs ? `span ${rs}` : undefined
      }}
      onMouseLeave={onMouseLeave}
      id={uid}
    >
      {!itemsRender.length && hoverItem && hoverItem.className !== "text.container.TableCell" && (
        <div className="item-preview-holder">
          {hoverItemRender}
        </div>
      )}
      <Dnd.Target
        drop={onDrop}
        canDrop={canDrop}
        hover={onHover}
        endHover={endHover}
        accept={accept}
        className="table-cell-dnd-target"
      />
      {itemsRender}
      {!itemsRender.length && !hoverItem && canSetEdit && (
        <ContextMenu.Trigger
          menuId={data.editable}
          context={data}
          collect={cellContextMenuCollect}
          className={"plus-container"}
          onClick={true}
        >
          <AisIcon
            onContextMenu={toggleExpanded}
            icon={"plus-M"}
            className={"expand-menu"}
          />
          <span className="plus-start-text">Нажмите, чтобы добавить</span>
        </ContextMenu.Trigger>)}
      {isExpanded && <PlusMenu data={data} />}
      {!hoverItem && (
        <ContextMenu.Trigger
          context={data}
          menuId={data.editable}
          collect={contextMenuCollect}
          className="corner-holder"
        >
          <Dnd.Source
            canDrag={canDrag}
            end={endDrag}
            type={`table-cell-handle-${tableUid}`}
            item={data}
          >
            <AisIcon className={"icon"} item={data} />
          </Dnd.Source>
        </ContextMenu.Trigger>
      )}
      <Modal.Window
        name="dnd-dialog"
        icon="cut-M"
        show={isDndDialogVisible}
        title={"Перемещение объекта"}
        buttons={dndDialogButtons}
        onKeyPressEnter={onConfirmDnd}
        onKeyPressEsc={onCancelDnd}
      >
        {dndDialogContent}
      </Modal.Window>
    </div>
  );
});

export default TableCell;
